<template>
  <div class="root">
    <div class="qr-container">
      <vue-qr :text="link" :size="120" :margin="0" />
    </div>
    <div class="qr-title">{{ title }}</div>
    <div class="input">
      {{ link }}
      <button class="copy" @click="copy" :disabled="copied">
        <span v-if="!copied">{{ $t("payment.copyLink") }}</span>
        <img v-if="copied" src="../assets/done.svg" />
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    link: String,
    title: String,
  },

  components: {
    VueQr: () => import("vue-qr"),
  },

  data() {
    return {
      copyTimeout: null,
    };
  },

  methods: {
    copy() {
      navigator.clipboard.writeText(this.link);
      this.copyTimeout = setTimeout(() => {
        this.copyTimeout = null;
      }, 1000);
    },
  },

  computed: {
    copied() {
      return this.copyTimeout !== null;
    },
  },

  beforeUnmount() {
    if (this.copyTimeout) {
      clearTimeout(this.copyTimeout);
    }
  },
};
</script>

<style scoped>
.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
}

.qr-container {
  padding: 20px;
  border: 1px solid #ffffff1a;
  border-radius: 8px;
  background-color: #1f1e1e;
  margin-bottom: 12px;
}

.input {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  min-height: 42px;
  padding: 0 12px;
  background: #ffffff0f;
  color: #ffffff9a;
  font-size: 13px;
  border-radius: 8px;
  border: 1px dashed #ffffff1a;
  word-break: break-all;
}

.copy {
  display: flex;
  padding: 4px 8px;
  border-radius: 4px;
  gap: 4px;
  justify-content: center;
  align-items: center;
  background-color: #ffffff1f;
  font-size: 12px;
  width: 70px;
  height: 26px;
  flex-shrink: 0;
}

.qr-title {
  margin-bottom: 12px;
  font-size: 13px;
  color: #fff;
}
</style>
